<template>
    <div>
        <el-row :gutter="10">
            <el-col :lg="24">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>基本数据</span>
                        <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                    </div>
                    <div class="text item">
                        <el-row :gutter="10" class="panel-group">
                            
                            <!-- <el-col :xs="12" :sm="12" :lg="{span: '4-8'}" class="card-panel-col">
                            <div class="card-panel" @click="handleSetLineChartData('newVisitis')">
                                <div class="card-panel-icon-wrapper icon-people">
                                <i class="el-icon-school card-panel-icon" />
                                </div>
                                <div class="card-panel-description">
                                <div class="card-panel-text">
                                    合作医院总数
                                </div>
                                <div class="card-panel-num">
                                    {{enterpriseCount.count}}
                                </div>
                                </div>
                            </div>
                            </el-col> -->

                            <el-col :xs="12" :sm="12" :lg="{span: '4-8'}" class="card-panel-col">
                            <div class="card-panel">
                                <div class="card-panel-icon-wrapper icon-message">
                                <i class="el-icon-money card-panel-icon" />
                                </div>
                                <div class="card-panel-description">
                                <div class="card-panel-text">
                                    充值总金额
                                </div>
                                <div class="card-panel-num">
                                    {{rechargeRecordCountPrice.count}}
                                </div>
                                </div>
                            </div>
                            </el-col>

                            <el-col :xs="12" :sm="12" :lg="{span: '4-8'}" class="card-panel-col">
                            <div class="card-panel">
                                <div class="card-panel-icon-wrapper icon-money">
                                <i class="el-icon-wallet card-panel-icon" />
                                </div>
                                <div class="card-panel-description">
                                <div class="card-panel-text">
                                    充值总笔数
                                </div>
                                <div class="card-panel-num">
                                    {{rechargeRecordCount.count}}
                                </div>
                                </div>
                            </div>
                            </el-col>

                            <el-col :xs="12" :sm="12" :lg="{span: '4-8'}" class="card-panel-col">
                            <div class="card-panel">
                                <div class="card-panel-icon-wrapper icon-shopping">
                                <i class="el-icon-user card-panel-icon" />
                                </div>
                                <div class="card-panel-description">
                                <div class="card-panel-text">
                                    用户总数
                                </div>
                                <div class="card-panel-num">
                                    {{crmUserCount.count}}
                                </div>
                                </div>
                            </div>
                            </el-col>

                            <el-col :xs="12" :sm="12" :lg="{span: '4-8'}" class="card-panel-col">
                            <div class="card-panel">
                                <div class="card-panel-icon-wrapper icon-message">
                                <i class="el-icon-user-solid card-panel-icon" />
                                </div>
                                <div class="card-panel-description">
                                <div class="card-panel-text">
                                    剩余可分配客户数
                                </div>
                                <div class="card-panel-num">
                                    {{surplusCount.surplusCount}}
                                </div>
                                </div>
                            </div>
                            </el-col>

                        </el-row>
                    </div>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>绑定新线索微信通知步骤</span>
                        <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                    </div>
                    <div class="text item">
                        <el-row :gutter="10" class="panel-group">
                            
                            <el-col :xs="12" :sm="12" :lg="{span: '4-8'}" class="card-panel-col">
                            <el-image
                                    style="width: 150px; height: 150px"
                                    src="/erweima.jpg"></el-image>
                            <div class="card-panel-num">
                                    第一步：扫码并关注公众号
                            </div>
                            </el-col>

                            <el-col :xs="12" :sm="12" :lg="{span: '4-8'}" class="card-panel-col">
                            <div class="card-panel-num">
                                    第二步：点击菜单“绑定通知”
                            </div>
                            </el-col>

                            <el-col :xs="12" :sm="12" :lg="{span: '4-8'}" class="card-panel-col">
                            <div class="card-panel-num">
                                    第三步：输入表单系统的用户名和密码
                            </div>
                            </el-col>

                            <el-col :xs="12" :sm="12" :lg="{span: '4-8'}" class="card-panel-col">
                            <div class="card-panel-num">
                                    第四步：点击关闭即可
                            </div>
                            </el-col>

                        </el-row>
                    </div>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>数据统计</span>
                        
                        <!-- <div class="" style="float:right;margin-top:-8px;">
                            <span class="demonstration">时间  </span>
                            <el-date-picker
                            v-model="value2"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            @change="selectTimeRange(value2)"
                            value-format="yyyy-MM-dd">
                            
                            </el-date-picker>
                        </div> -->
                        
                    </div>
                    <el-row :gutter="10">
                        <el-col :lg="12">
                            <div class="text item">
                                <div class="text item">
                                    <div id="rechargePriceChart" class="pie-wrap" style="width: 100%; height: 350px;"></div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :lg="12">
                            <div class="text item">
                                <div class="text item">
                                    <div id="userChart" class="pie-wrap" style="width: 100%; height: 350px;"></div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            
        </el-row>
        
    </div>
</template>

<script>
import echarts from "echarts";
export default {
    data(){
        return{
            enterpriseCount:[],//合作医院总数
            rechargeRecordCount:[],//充值记录总条数
            crmUserCount:[],//用户总数
            rechargeRecordCountPrice:[],//充值记录总金额
            surplusCount:[],//剩余可分配客户数

            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            value1: '',
            value2: '',

            day:{
                countDay:[],
                userCount:[],
                rechargePrice:[],

            },

            userCountByDay:{
                countDay:[],
                userCount:[],
                rechargePrice:[],

            },
            orderAndCoupon:{
                countDay:[],
                couponPriceList:[],
                taskPriceList:[]
            },

            queryInfo:{
                beginTime:'',
                endTime:''
            },

        }
    },
    created(){
        //this.getEnterpriseCount();
        this.getRechargeRecordCount();
        this.getRechargeRecordCountPrice();
        this.getCrmUserCount();
        this.getSurplusCount();

        this.getDayCount();

        this.getDayCountUsers();
        //this.getOrderAndCouponCount();

    },
    mounted() {
        this.$nextTick(() => { 
            
            //this.drawOrderChart();
            //this.drawRechargePriceChart(); 
        });
    },
    methods:{
        
        // async getEnterpriseCount(){//合作医院总数
        //    //console.log("getUserCount????????");
        //    const {data:res} = await this.$http.get('crm/enterprise/count');
        //    //console.log(res);
        //    if(res.code !== 200) return this.$message.error('获取合作医院总数数据错误')
        //    this.enterpriseCount = res.result;
        //    //console.log(this.farmerCount);
        //    //this.total = res.result.total;
        // },
        async getRechargeRecordCount(){//充值记录总数
           //console.log("getUserCount????????");
           const {data:res} = await this.$http.get('crm/rechargerecord/rechargeCount');
           //console.log(res);
           if(res.code !== 200) return this.$message.error('获取充值记录总数数据错误')
           this.rechargeRecordCount = res.result;
           //console.log(this.farmerCount);
           //this.total = res.result.total;
        },
        async getRechargeRecordCountPrice(){//充值金额总数
           //console.log("getUserCount????????");
           const {data:res} = await this.$http.get('crm/rechargerecord/rechargeCountPrice');
           //console.log(res);
           if(res.code !== 200) return this.$message.error('获取充值金额总数数据错误')
           this.rechargeRecordCountPrice = res.result;
           //console.log(this.farmerCount);
           //this.total = res.result.total;
        },
        async getCrmUserCount(){//用户总数
           //console.log("getUserCount????????");
           const {data:res} = await this.$http.get('crm/user/usercount');
           //console.log(res);
           if(res.code !== 200) return this.$message.error('获取用户总数数据错误')
           this.crmUserCount = res.result;
           //console.log(this.farmerCount);
           //this.total = res.result.total;
        },

        async getSurplusCount(){
             const {data:res} = await this.$http.get('crm/enterprise/queryById/');
             console.log("getSurplusCount");
           console.log(res);
           if(res.code !== 200) return this.$message.error('获取剩余可分配客户数数据错误')
           this.surplusCount = res.result;
        },
        
        
        async getDayCount(){//按天统计
           
           const {data:res} = await this.$http.get('crm/rechargerecord/statisticsByDay',{params:this.queryInfo});
           console.log("getDayCount????????");
           console.log(res);
           if(res.code !== 200) return this.$message.error('获取按天统计数据')
           //this.day = res.result.count;

            this.day.countDay=res.result.countDay;
            //this.day.userCount=res.result.userCount;
            this.day.rechargePrice=res.result.rechargePrice;
           //console.log(this.day.countDay);
           this.$nextTick(() => { 
                this.drawRechargePriceChart(); 
                //this.drawUserChart(); 
            });
           //this.total = res.result.total;
        },
        async getDayCountUsers(){//按天统计-用户数-企业权限
           
           const {data:res} = await this.$http.get('crm/user/usercountByDay',{params:this.queryInfo});
           console.log("getDayCountUsers????????");
           console.log(res);
           if(res.code !== 200) return this.$message.error('获取按天统计用户数据')
           //this.day = res.result.count;

            this.userCountByDay.countDay=res.result.countDay;
            this.userCountByDay.userCount=res.result.userCount;
            //this.userCountByDay.rechargePrice=res.result.rechargePrice;
           //console.log(this.day.countDay);
           this.$nextTick(() => { 
                //this.drawRechargePriceChart(); 
                this.drawUserChart(); 
            });
           //this.total = res.result.total;
        },
        selectTimeRange(value2){
            if(value2==null) {
                this.value2 = [];
                this.queryInfo.beginTime = '';
                this.queryInfo.endTime = '';
            }else{
                this.queryInfo.beginTime = value2[0];
                this.queryInfo.endTime = value2[1];
            }
            
            console.log(value2+"~~~~~~~~~~~");
            this.getDayCount();
        },

       
        drawUserChart() {
            console.log("*****drawUserChart******")
            this.userChart = echarts.init(
                document.getElementById("userChart")
            );
            this.userChart.setOption(
                {
                    // title: {
                    //     text: '月度销量数据'
                    // },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#19a97b'
                            }
                        }
                    },
                    legend: {
                        data: ['用户数']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: this.userCountByDay.countDay
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '用户数',
                            type: 'line',
                            stack: '总量',
                            // areaStyle: {},
                            // emphasis: {
                            //     focus: 'series'
                            // },
                            data: this.userCountByDay.userCount,
                            smooth: true,
                            color:'#19a97b'
                        },
                        
                    ]
                }
            )
        },
        drawRechargePriceChart() {
            console.log("*****drawRechargePriceChart******")
            this.rechargePriceChart = echarts.init(
                document.getElementById("rechargePriceChart")
                
            );
            this.rechargePriceChart.setOption(
                {
                    // title: {
                    //     text: '月度销量数据'
                    // },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#19a97b'
                            }
                        }
                    },
                    legend: {
                        data: ['充值金额']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: this.day.countDay
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        
                        {
                            name: '充值金额',
                            type: 'line',
                            stack: '总量',
                            label: {
                                show: true,
                                position: 'top'
                            },
                            data: this.day.rechargePrice,
                            smooth: true,
                        },
                        
                    ]
                }
            )
        },
        
    }
}
</script>

<style lang="less" scoped>
ul{
    list-style: none;
    margin:0px;
    padding:0px;
}
li{
    overflow:hidden; 
    text-overflow:ellipsis;
    white-space:nowrap;
    font-size: 14px;
    line-height: 30px;
}
.box-card{
    margin-bottom: 10px;
}
.panel-group {
//   margin-top: 18px;
  .card-panel-col {
    margin-bottom: 10px;
  }
  .card-panel {
    // height: 108px;
    height: 88px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    // overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);
    display: flex;
    align-items: center;
    // justify-content: center;
    .qr-code {
        width: 100px;
        height: 100px;
        border-radius: 8px;
        border: 2px solid #358f6c;
        position: absolute;
        left: -48px;
        top: 50%;
        margin-top: -55px;
        box-shadow: 0 2x 2px rgb(0 0 0 / 15%);
        display: none;
        background-color: #fff;
        z-index: 2;
        padding: 5px;
        &::after{
            content: '';
            width:0px;
            height:0px;
            border-left:10px solid #358f6c;
            border-top:5px solid transparent;
            border-bottom:5px solid transparent; 
            position: absolute;
            right: -10px;
            top: 30px;
        }
    }
    &:hover {
      .qr-code {
          display: block;
      }  
      .card-panel-icon-wrapper {
        color: #fff;
      }
      .icon-people {
        background: #40c9c6;
      }
      .icon-message {
        background: #36a3f7;
      }
      .icon-money {
        background: #f4516c;
      }
      .icon-shopping {
        background: #34bfa3
      }
    }
    .icon-people {
      color: #40c9c6;
    }
    .icon-message {
      color: #36a3f7;
    }
    .icon-money {
      color: #f4516c;
    }
    .icon-shopping {
      color: #34bfa3
    }
    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
    //   padding: 16px;
       padding: 6px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }
    .card-panel-icon {
      float: left;
    //   font-size: 48px;
    font-size: 40px;
    }
    .card-panel-description {
      float: left;
      font-weight: bold;
    //   margin: 26px;
        margin-top: 16px;
        margin-right: 6px;
      margin-left: 6px;
      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }
      .card-panel-num {
        font-size: 20px;
      }
      .card-panel-num-right {
        font-size: 12px;
      }
    }
    .card-panel-description-daiban {
    //   float: left;
      font-weight: bold;
    //   margin: 26px;
        margin-top: 16px;
        margin-right: 6px;
      margin-left: 6px;
      
      .card-panel-text-daiban {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        margin-bottom: 12px;
        text-align: center;
      }
      .card-panel-num-daiban {
        font-size: 20px;
        text-align: center;
      }
    }
  }
}
.daibanpanel{
    .card-panel{
        justify-content: center;
    }
}
@media only screen and (min-width:768px) {

    .el-col-sm-4-8 {width: 25%}

    .el-col-sm-offset-4-8 {margin-left: 25%}

    .el-col-sm-pull-4-8 {position: relative;right: 25%}

    .el-col-sm-push-4-8 {position: relative;left: 25%}

}

@media only screen and (min-width:992px) {

    .el-col-md-4-8 {width: 25%}

    .el-col-md-offset-4-8 {margin-left: 25%}

    .el-col-md-pull-4-8 {position: relative;right: 25%}

    .el-col-md-push-4-8 {position: relative;left: 25%}

}

@media only screen and (min-width:1200px) {

    .el-col-lg-4-8 {width: 25%}

    .el-col-lg-offset-4-8 {margin-left: 25%}

    .el-col-lg-pull-4-8 {position: relative;right: 25%}

    .el-col-lg-push-4-8 {position: relative;left: 25%}

}

@media only screen and (min-width:1920px) {

    .el-col-xl-4-8 {width: 25%}

    .el-col-xl-offset-4-8 {margin-left: 25%}

    .el-col-xl-pull-4-8 {position: relative;right: 25%}

    .el-col-xl-push-4-8 {position: relative;left: 25%}

}
</style>